@use "layouts/css/variables.scss" as *;

#accordionMenuDiv {
  width: 220px;
  background: $white;

  & .accordionHeading {
    height: 30px;
    padding-left: 10px;
    line-height: 33px;
    cursor: pointer;
    color: $fontGrey;

    &.current {
      height: 30px;
      padding-left: 10px;
      line-height: 33px;
      background-color: $mainPurple;
      color: $white;
      border-top: 1px solid $lightGrey;
    }
  }

  & a {
    text-decoration: none;

    & .accordionSubHeading {
      margin: 5px 0px 5px 15px;
      font-family: $headingFont;
      font-size: 16px;
      font-weight: 600;
      color: $fontGrey;
    }

    &:hover .accordionSubHeading {
      background-color: $lightGrey;
    }
  }

  & .accordionSubHeading.current {
    color: $mainPurple;
  }

  & .amSub {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & .amDivider {
    height: 1px;
    background-color: $lightGrey;
  }
}

.icon {
  vertical-align: middle;
  height: 20px;
  padding-bottom: 6px;
}

.iconDiv {
  width: 30px;
  height: 25px;
  float: left;
  margin: 0px 5px 0px 0px;
  padding: 0;
  text-align: center;
}
