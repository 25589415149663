/* Colors */
$mainBackground: #FFFFFF;
$white: #FFFFFF;
$lightGrey: #F2F2F2;
$darkGrey: #999999;
$fontGrey: #666666;
$mainPurple: #4D1549;

/* Sizes */
$contentWidth: 960px;
$homeContentWidth: 959px;
$imageHeight: 360px;
$topContentHeight: 150px;

$headingFont: "refrigerator-deluxe"; /* Possible replacement: Saira Condensed */
