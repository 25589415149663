@use "./variables.scss" as *;

body {
  background-color: $mainBackground;
  margin: 0;
  padding: 0;
  color: $fontGrey;
}

img {
  border: 0;
}

a {
  text-decoration: none;
}

.colorFont {
  color: $mainPurple;
}

.greyFont {
  color: $darkGrey;
}

.bodyText {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.loginDiv {
  background-color: $white;
  padding: 20px;
  width: 133px;
}

.more {
  font-family: $headingFont;
  font-size: 14px;
  font-weight: 800;
  font-style: italic;
}

.subHeading {
  font-family: $headingFont;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
}

.heading {
  font-family: $headingFont;
  font-size: 20px;
  font-weight: 800;
}

.title {
  font-family: $headingFont;
  font-size: 26px;
  font-weight: 200;
  line-height: 1em;
}

.contentHeading {
  font-family: $headingFont;
  font-size: 16px;
  font-weight: 800;
  color: $mainPurple;
}

a {
  color: $mainPurple;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.disciplineHeading {
  font-size: 14px;
  color: $mainPurple;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.disciplineContent {
  display: none;
  padding-left: 10px;
  background-color: $lightGrey;
  padding-top: 15px;
  margin-bottom: 10px;
}

.courseDetails {
  padding-bottom: 10px;
}

.footer {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 20px;
  margin-top: 10px;
}

.copyRight {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 11px;
  line-height: 20px;
  margin-top: 5px;
}

table.highlightTable {
  background-color: $lightGrey;
  padding-left: 10px;
  margin-left: 5px;
}

.smallCaps {
  font-size: 0.8em;
}

table.courseDetails {
  border: 1px solid $darkGrey;
  width: 400px;
  border-spacing: 0px;

  & td {
    padding: 2px 5px;
  }

  & tr:nth-child(even) td {
    background-color: $lightGrey;
  }

  & th {
    font-size: 14px;
  }
}
