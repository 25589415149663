@use "layouts/css/variables.scss" as *;

.menuBackground {
  background-color: $darkGrey;
  height: $imageHeight;
  text-align: center;
  box-shadow: 0px 5px 10px 0px #CCCCCC;
}

.homeIcon {
  vertical-align: middle;
  height: 20px;
  padding-bottom: 6px;
  box-sizing: content-box;
}

.sim-container {
  margin: 0 auto;
  width: 960px;
  height: 360px;
  position: relative;
  display: flex;
  font-family: $headingFont;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  overflow: hidden;

  & .sim-default-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  & > .sim-panel {
    display: inline-block;
    position: relative;
    z-index: 2;
    width: 240px;
    height: 360px;
    border-right: 1px solid white;
    overflow: hidden;

    &:last-child {
      border-right: none;
    }

    & .sim-label {
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 4;

      font-size: 20px;
      padding-left: 20px;
      width: 100%;
      background: #000;
      text-align: left;
      height: 40px;
      line-height: 43px;
      opacity: 0.6;
      cursor: pointer;
      text-shadow: 0px 0px 1px #000;
      text-decoration: none;
      color: #fff;
      outline: none;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }

      &:hover + .sim-menu {
        bottom: 40px;
        transition: bottom 0.3s;
      }
    }

    & .sim-menu {
      position: absolute;
      z-index: 2;
      bottom: -360px;
      left: 0;
      width: 100%;
      padding: 10px 0;
      margin: 0;
      opacity: 0.8;
      list-style: none;
      text-align: left;
      background-color: black;
      transition: bottom 0.8s;
      transition-delay: 0.3s;

      &:hover {
        bottom: 40px;
      }

      & a {
        text-decoration: none;
        padding-left: 20px;
        color: $white;
      }
    }

    & .sim-bg-master {
      display: none;
    }

    & .sim-bg {
      position: absolute;
      top: 0;
      left: 240px;
      transition: left 250ms ease-in-out;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &.active {
        left: 0;
      }
    }

    &:nth-child(2) .sim-bg.active {
      transition-delay: 300ms;
    }

    &:nth-child(3) .sim-bg {
      transition-delay: 100ms;
      &.active { transition-delay: 200ms; }
      & img { margin-left: -240px; }
    }

    &:nth-child(4) .sim-bg {
      transition-delay: 200ms;
      &.active { transition-delay: 100ms; }
      & img { margin-left: -480px; }
    }

    &:nth-child(5) .sim-bg {
      transition-delay: 300ms;
      &.active { transition-delay: 0ms; }
      & img { margin-left: -720px; }
    }
  }
}
