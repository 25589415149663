@use "layouts/css/variables.scss" as *;

.ui-widget {
  font-family: Verdana,Arial,sans-serif;
  font-size: 12px;
}

.ui-widget-header {
  background-color: $lightGrey;
  color: $fontGrey;
  font-weight: bold;
  border-left: 1px solid $white;
  border-right: 1px solid $white;
}

.ui-state-hover {
  background-color: $darkGrey;
  font-weight: normal;
}

.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-icon {
  width: 16px;
  height: 16px;
  background-image: url("../img/ui-icons_222222_256x240.png");
}

.ui-icon-circle-triangle-e {
  background-position: -32px -48px;
}

.ui-icon-circle-triangle-w {
  background-position: -96px -48px;
}

.ui-datepicker {
  padding: 0;
  display: none;

  & .ui-datepicker-header {
    position:relative;
    padding: 2px 0;
  }

  & .ui-datepicker-prev, & .ui-datepicker-next {
    position:absolute;
    width: 21px;
    height: 21px;

    & span {
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -8px;
      top: 50%;
      margin-top: -8px;
    }
  }

  & .ui-datepicker-prev, & .ui-datepicker-prev-hover {
    top: 2px;
    left: 2px;
  }

  & .ui-datepicker-next, & .ui-datepicker-next-hover {
    top: 2px;
    right: 2px;
  }

  & .ui-datepicker-title {
    line-height: 21px;
    text-align: center;
  }

  & table {
    width: 100%;
    font-size: 11px;
    border-collapse: collapse;
    margin: 0;
  }

  & th {
    padding: 6px 0px;
    text-align: center;
    font-weight: bold;
    border: 1px solid $white;
    background-color: $lightGrey;
  }

  & td {
    border: 1px solid $white;
    padding: 0;
    background-color: $lightGrey;
    padding: 6px 0px;

    & span, & a {
      display: block;
      padding: 0;
      text-align: center;
      text-decoration: none;
    }
  }
}

td.ui-datepicker-today {
  background-color: $darkGrey;
}

td.event {
  background-color: $mainPurple;
  color: $white;
}

div.ui-cluetip {
  font-size: 11px;
}

.ui-cluetip-header, .ui-cluetip-content {
  padding: 5px;
}

.ui-cluetip-header {
  font-size: 11px;
  margin: 0;
  overflow: hidden;
}

.cluetip {
  border: 1px solid $darkGrey;
  background-color: $white;

  & .ui-widget-header {
    border: 0px;
  }
}

.cluetip-default {
  background-color: $white;
  color: $mainPurple;

  & .ui-cluetip-header {
    background-color: $mainPurple;
    color: $white;
  }
}
