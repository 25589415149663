@use "layouts/css/variables.scss" as *;

.slider {
  background-color: #000000;
  height: $imageHeight;
  box-shadow: 0px 10px 10px #CCC;
  text-align: center;
  overflow: hidden;
}

.sliderItem {
  margin-left: auto;
  margin-right: auto;
  width: $contentWidth;
  text-align: left;
  position: relative;
}

.prevImage {
  position: absolute;
  left: calc(-1 * $contentWidth);
  top: 0;
  opacity: 0.4;
  width: $contentWidth;
  height: $imageHeight;
}

.nextImage {
  position: absolute;
  top: 0;
  right: calc(-1 * $contentWidth);
  opacity: 0.4;
  width: $contentWidth;
  height: $imageHeight;
}

.mainImage {
  width: $contentWidth;
  height: $imageHeight;
}

.sliderControls {
  position: absolute;
  z-index: 100;
  left: 50%;
  bottom: 20px;
  width: 98px;
  height: 32px;
  margin-left: -460px;
  overflow: hidden;

  & span {
    float: left;
    width: 32px;
    height: 32px;
    background-image: url("../img/carousel_controls.gif");

    &:hover {
      cursor: pointer;
    }
  }

  & .next {
    background-position: -99px -32px;
    border-left: 1px solid #333;

    &:hover, &:focus {
      background-position: -99px 0;
      border-color: #ccc;
    }
  }

  & .previous {
    background-position: 0 -32px;

    &:hover, &:focus {
      background-position: 0 0;
    }
  }

  & .pause {
    background-position: -33px -32px;
    border-left: 1px solid #333;

    &:hover, &:focus {
      background-position: -33px 0;
      border-color: #ccc;
    }
  }

  & .play {
    position: relative;
    bottom: auto;
    left: auto;
    background-position: -66px -32px;
    border-left: 1px solid #333;
  }

  &:hover, &:focus {
    background-position: -66px 0;
    border-color: #ccc;
  }
}
