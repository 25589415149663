@use "./variables.scss" as *;

nav {
  font-family: "Advent Pro", sans-serif;
  font-size: 8pt;
  float: right;
  white-space: nowrap;
  background-color: $lightGrey;

  & ul {
    margin: 0;
    padding: 0;

    & li {
      list-style-type: none;
      display: inline-block;
      padding: 5px 0px;
      width: 115px;
      border-left: 1px solid $mainBackground;
      text-align: center;

      & a {
        padding: 0 5px;
        font-weight: bold;
        text-decoration: none;
        color: $mainPurple;

        &:hover {
          text-decoration: underline;
        }
      }

      &.opposite {
        list-style-type: none;
        display: inline-block;
        padding: 5px 0px;
        width: 119px;
        border-left: 1px solid $mainBackground;
        background-color: $mainPurple;
        text-align: center;

        & a {
          color: $white;
        }
      }
    }
  }
}
