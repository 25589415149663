@use "./variables.scss" as *;

.middleWrapper {
  text-align: center;
}

.innerContent {
  margin-left: auto;
  margin-right: auto;
  width: $contentWidth;
  text-align: left;
}

.innerContentTop {
  margin-left: auto;
  margin-right: auto;
  width: $contentWidth;
  text-align: left;
  position: relative;
  height: $topContentHeight;
}

.homeInnerContent {
  margin-left: auto;
  margin-right: auto;
  width: $homeContentWidth;
  text-align: left;
}

.homeContent {
  background-color: $lightGrey;
  padding: 20px;
  box-shadow: inset 0px 15px 10px -10px $darkGrey;

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  &, & * {
    box-sizing: content-box;
  }
}

.homeInnerContentTop {
  margin-left: auto;
  margin-right: auto;
  width: $homeContentWidth;
  text-align: left;
  position: relative;
  height: $topContentHeight;
}

.banner {
  position: absolute;
  top: 15px;
}

.content {
  background-color: $lightGrey;
  padding: 20px;
}

.homeLeftContent {
  float: left;

  & .homeSideContent {
    background-color: $white;
    width: 190px;
    padding: 14px 14px 6px 16px;
  }

  & .calendarContent {
    width: 193px;
  }
}

.calendarContent {
  background-color: $white;
  min-height: 200px;
  padding: 14px 14px 14px 13px;
  margin-top: 20px;
}

.upcomingContent {
  background-color: $white;
  width: 302px;
  min-height: 420px;
  padding: 14px 14px 14px 14px;
  margin-left: 20px;
  float: left;
}

.newsContent {
  background-color: $white;
  width: 649px;
  min-height: 420px;
  padding: 14px 14px 14px 16px;
  margin-left: 20px;
  float: left;
}

.mainContent {
  background-color: $white;
  width: 640px;
  margin-left: 20px;
  padding: 20px;
  float: left;
  overflow: auto;
}

.headingContent {
  background-color: $white;
  width: 180px;
  height: 90px;
  padding: 20px;
}

.subHeadingContent {
  background-color: $white;
  width: 180px;
  height: 180px;
  padding: 20px;
  margin-top: 20px;
}

.indent {
  padding-left: 20px;
}

.homeContentImageDiv {
  text-align: center;
  margin-bottom: 10px;
}

.contentDividerContainer {
  position: relative;
}

.contentDivider {
  position: absolute;
  height: 1px;
  background-color: $lightGrey;
  left: -20px;
  width: 680px;
}

.contentDividerContainerWithMargin {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}
